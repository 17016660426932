import { DatadogLoggingService } from '@edx/frontend-logging';

class CustomDatadogLoggingService extends DatadogLoggingService {
  _cleanEventView(eventString, expression = /u\/[^&]*/, replacer = 'u/*') {
    return eventString.replace(expression, replacer);
  }

  beforeSend(event, context) {
    const baseBeforeSendResult = super.beforeSend(event, context);
    // perform any common/shared logic for `beforeSend` defined within DatadogLoggingService.
    if (!baseBeforeSendResult) {
      // base `beforeSend` logic denotes the event should be discarded; no need to do addtl MFE-specific checks
      return false;
    }

    // clean the view name and url of the username before sending to datadog
    if (/u\/[^&]*/.test(event.view.url)) {
      event.view.name = this._cleanEventView(event.view.name);
      event.view.url = this._cleanEventView(event.view.url);
    }
    // occasionally the referral URL happens to also be the Profile page
    if (/u\/[^&]*/.test(event.view.referrer)) {
      event.view.referrer = this._cleanEventView(event.view.referrer);
    }
    // occasionally a resource URL will include the username
    if (/u\/[^&]*/.test(event.resource.url)) {
      event.resource.url = this._cleanEventView(event.resource.url, /u\/[^&]*\//, 'u/*/');
    }

    return true;
  }
}

const config = {
  loggingService: CustomDatadogLoggingService,
};

export default config;
